@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Nyala, Candara, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #2c2c2c;
  background-color: #f4f4f4;
}

a {
  color: #10aab0;
  text-decoration: none;
}

a.over {
  color: #2c2c2c;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.textOJsoulignerVert {
  text-decoration: underline #10aab0;
}

.textOJsoulignerNoir {
  text-decoration: underline #2c2c2c;
}

.errorPageRouting {
  background-image: url("/error-encapsulation.png");
  background-repeat: no-repeat;
  background-size: 208px;

  @apply text-5xl h-52 w-52 pt-24 pl-24;
}
